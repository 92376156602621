<template>
  <div>
    <div v-if="characters && characters.length">
      <Grid :lg="3" :md="2" :sm="2" :scrollSm="false">
        <CharacterCard
          :openBlank="true"
          v-for="character of characters"
          :key="character.id"
          class="grid-item"
          :character="character"
          :char-comments-count="get(charCommentsCount, `${get(character, 'id')}.comments_count`) || 0"
        />
      </Grid>

      <ion-button v-show="nextPageExists" class="clickable" @click="requestLoadMore">Load More</ion-button>
    </div>
    <div v-else class="no-data">No reactions yet</div>
  </div>
</template>

<script lang="ts" setup>
import Grid from '@/shared/components/storage/Grid.vue';
import CharacterCard from '@/shared/components/storage/CharacterCard.vue';
import { getCommentsCount } from '@/shared/actions/comments';
import constants from '@/shared/statics/constants';

const props = defineProps({ characters: { type: Array }, paging: { type: Object } });
const emits = defineEmits(['load']);
const paging = toRef(props, 'paging');
const characters = toRef(props, 'characters');
const charCommentsCount = ref({});
const nextPageExists = computed(() => {
  return !!paging.value?.next;
});

const requestLoadMore = () => {
  emits('load');
};

const fetchCharsCommentCounts = async () => {
  const resp = await getCommentsCount(map(characters.value, 'id') as string[], constants.commentedAsTypes.CHARACTER);
  charCommentsCount.value = keyBy(get(resp, 'counts', []), 'commented_on_object_id');
};
watch(
  characters,
  () => {
    fetchCharsCommentCounts();
  },
  { immediate: true, deep: true }
);
</script>

<style scoped lang="sass"></style>
