<template>
  <ion-page class="page scrollable bg-transparent">
    <!-- <reactions-tabs class="my-2" :tabs="tabs" :active-index="tabIndex" @tab-changed="tabChanged" /> -->

    <ReactionsTabs class="my-2" :tabs="tabs" :active-index="tabIndex" @tab-changed="tabChanged" />
    <infinite-characters-reaction
      v-if="currentTab === 'characters'"
      :characters="characters"
      :paging="charsPaging"
      @load="requestLoadMore"
      @updated="getCharactersRections"
    />
    <infinite-worlds-reaction
      v-if="currentTab === 'worlds'"
      :worlds="worlds"
      :paging="worldsPaging"
      @load="requestLoadMoreWorlds"
      @updated="getWorldsReactions"
    />

    <infinite-blabs-reaction
      v-if="currentTab === 'blabs'"
      :blabs="blabs"
      :current-tab="currentTab"
      :paging="blabspaging"
      :total-blabs="totalBlabs"
      @load="requestLoadMoreBlabs"
      @updated="getBlabsReactions"
    />
    <infinite-social-spaces
      v-if="currentTab === 'social'"
      :social-spaces="spaces"
      :current-tab="currentTab"
      :paging="spacespaging"
      @load="requestLoadMoreSpaces"
      @updated="getSocialSpacesReactions"
    />

    <infinite-visuals-reaction
      v-if="currentTab === 'visuals'"
      :visuals="images"
      :paging="imagespaging"
      @load="requestLoadMoreVisuals"
      @updated="getImagesReactions"
    />
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import ReactionsTabs from './components/ReactionsTabs.vue';
import InfiniteCharactersReaction from './components/InfiniteCharactersReaction.vue';
import InfiniteWorldsReaction from './components/InfiniteWorldsReaction.vue';
import InfiniteBlabsReaction from './components/InfiniteBlabsReaction.vue';
import InfiniteVisualsReaction from './components/InfiniteVisualsReaction.vue';
import InfiniteSocialSpaces from './components/InfiniteSocialSpace.vue';
import { Paging, socials, Tab } from '@/shared/types/static-types';
import { reactionsTabs } from '@/shared/statics/tabs';
import { getCharactersReactions } from '@/shared/actions/characters';
import { getWorldsReactions } from '@/shared/actions/worlds';
import { getBlabsReactions } from '@/shared/actions/blabs';
import { getImagesReactions } from '@/shared/actions/imagesCollections';
import { getNextPage } from '@/shared/helpers/pagination';

@Options({
  name: 'ReactionsPage',
  components: {
    ReactionsTabs,
    InfiniteCharactersReaction,
    InfiniteWorldsReaction,
    InfiniteBlabsReaction,
    InfiniteVisualsReaction,
    InfiniteSocialSpaces,
  },
})
export default class ReactionsPage extends Vue {
  public tabs = reactionsTabs;
  public currentTab: string = 'characters';
  public tabIndex = 0;
  public pageNum = 1;
  public characters: any = [];
  public worlds: any = [];
  public spaces: any = [];
  public blabs: any = [];
  public totalBlabs: number = 0;

  public images: any = [];
  public charsPaging: Paging | null = null;
  public worldsPaging: Paging | null = null;
  public blabspaging: Paging | null = null;
  public imagespaging: Paging | null = null;
  public spacespaging: Paging | null = null;
  isEmpty = isEmpty;
  get = get;
  public get defaultRoute() {
    const route = useRoute();
    return route;
  }

  public tabChanged({ value }: Tab) {
    const router = useRouter();
    this.currentTab = value;
  }

  public async getCharactersRections(page = 1) {
    const { results, ...paging } = await getCharactersReactions(page);
    this.characters = results;
    this.charsPaging = paging;
  }

  public async getWorldsReactions(page = 1) {
    const { results, ...paging } = await getWorldsReactions('story', page);
    this.worlds = results;
    this.worldsPaging = paging;
  }

  public async getSocialSpacesReactions(page = 1) {
    const { results, ...paging } = await getWorldsReactions('socialspace', page);
    this.spaces = results;
    this.spacespaging = paging;
  }

  public async getBlabsReactions() {
    const resp = await getBlabsReactions(this.pageNum);
    this.blabs = this.blabs.concat(resp.results);
    this.totalBlabs = resp.count;
  }

  public async getImagesReactions(page = 1) {
    const { results, ...paging } = await getImagesReactions(page);
    this.images = results;
    this.imagespaging = paging;
  }

  public async requestLoadMore() {
    try {
      const { results, ...paging } = await getNextPage(this.charsPaging!);
      this.characters = this.characters.concat(results);
      this.charsPaging = paging;
    } catch (e) {}
  }

  public async requestLoadMoreWorlds() {
    try {
      const { results, ...paging } = await getNextPage(this.worldsPaging!);
      this.worlds = this.worlds.concat(results);
      this.worldsPaging = paging;
    } catch (e) {}
  }

  public async requestLoadMoreBlabs() {
    try {
      this.pageNum++;
      this.getBlabsReactions();
    } catch (e) {}
  }

  public async requestLoadMoreSpaces() {
    try {
      const { results, ...paging } = await getNextPage(this.spacespaging!);
      this.spaces = this.spaces.concat(results);
      this.spacespaging = paging;
    } catch (e) {}
  }

  public async requestLoadMoreVisuals() {
    try {
      const { results, ...paging } = await getNextPage(this.imagespaging!);
      this.images = this.images.concat(results);
      this.imagespaging = paging;
    } catch (e) {}
  }

  public async created() {
    const router = useRouter();
    const document = useDocument();
    document.value?.getElementById('app-nav-bar')?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

    this.getCharactersRections();
    this.getWorldsReactions();
    this.getBlabsReactions();
    this.getImagesReactions();
    this.getSocialSpacesReactions();

    const tIndex = indexOf(
      this.tabs,
      find(this.tabs, (tab) => tab.value === router.currentRoute.value.query.tab)
    );
    if (router.currentRoute.value.query.tab && tIndex !== -1) {
      this.tabIndex = tIndex;
      this.currentTab = router.currentRoute.value.query.tab;
    }
  }
}
</script>
<style lang="sass" scoped></style>
