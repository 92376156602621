<template>
  <div>
    <div v-if="worlds && worlds.length">
      <Grid :lg="2" :md="2" :sm="1" :scrollSm="false">
        <StoryWorldCard :openBlank="true" class="grid-item" v-for="world of worlds" :key="world.id" :world="world" />
      </Grid>

      <ion-button v-if="nextPageExists" class="clickable" @click="requestLoadMore">Load More</ion-button>
    </div>
    <div v-else class="no-data">No reactions yet</div>
  </div>
</template>

<script lang="ts" setup>
import Grid from '@/shared/components/storage/Grid.vue';
import StoryWorldCard from '@/shared/components/storage/StoryWorldCard.vue';

const props = defineProps({ worlds: { type: Array }, paging: { type: Object } });
const emits = defineEmits(['load']);
const paging = toRef(props, 'paging');

const nextPageExists = computed(() => {
  return !!paging.value?.next;
});

const requestLoadMore = () => {
  emits('load');
};
</script>

<style scoped lang="sass"></style>
