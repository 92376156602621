<template>
  <div v-if="visuals && visuals.length" class="mt-2 mx-auto">
    <visuals-card :visuals="visuals" @onReaction="(reactionResp) => onReaction(reactionResp)" @load="requestLoadMore" />
    <p v-if="nextPageExists" class="clickable d-flex justify-content-center" @click="requestLoadMore">Load More</p>
  </div>
  <div v-else class="no-data">No reactions yet</div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import { Prop } from 'vue-property-decorator';
import VisualsCard from '@/shared/components/VisualsCard.vue';
import { Image } from '@/shared/types/static-types';

@Options({
  name: 'InfiniteVisuals',
  components: {
    VisualsCard,
  },
})
export default class InfiniteVisuals extends Vue {
  @Prop() public visuals!: any;
  @Prop() public currentTab!: string;
  @Prop() public paging!: any;

  repliesCounts = {};
  public isEdit = false;

  get = get;
  blabid = '';

  public get nextPageExists() {
    return !!this.paging?.next;
  }

  public requestLoadMore() {
    this.$emit('load');
  }

  public onReaction(event: { reactResp: {}; image: Image }) {
    const index = this.visuals.findIndex((visual: any) => visual.id === event.image.id);
    this.visuals[index] = { ...event.image, ...get(event.reactResp, 'updatedReactionsData', {}) };
  }
}
</script>

<style scoped lang="sass"></style>
