<template>
  <ion-card class="blab-card m-0 p-2">
    <div class="d-flex flex-column">
      <div class="d-flex">
        <img
          v-image
          class="img"
          :src="resizeUpload(get(reply.reply_as_char, 'info.cropProfilePicture', '/empty.png'), '80x80')"
        />
        <div class="ml-2 d-flex align-items-center justify-content-center">
          <div class="d-flex mt-1">
            <div v-if="!reply.reply_as_char">
              <router-link :to="{ name: 'profile', params: { username: get(reply, 'user.username') } }"
                >{{ truncateText(get(reply, 'user.username'), 15) }}
              </router-link>
            </div>
            <div v-else>
              <router-link :to="{ name: 'character-profile-new', params: { slug: get(reply.reply_as_char, 'slug') } }"
                >{{ truncateText(get(reply.reply_as_char, 'info.name'), 15) }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-end ml-auto">
        <small>{{ formatTimeAgo(reply.created) }}</small>
      </div>
    </div>

    <div class="description mt-3">{{ get(reply, 'title') }}</div>
    <div class="description">{{ truncateText(stripHtmlTags(get(reply, 'text', ''))) }}</div>

    <div v-if="showBlab" class="d-flex mt-2" :class="{ 'justify-content-end': !isMobSmallScreen }">
      <strong>Replied to:</strong>
      <router-link
        class="router-link clickable-item-hov ml-1"
        :to="{ name: 'post-details', params: { id: reply.blab.id } }"
      >
        {{ reply.blab.title }}
      </router-link>
    </div>

    <div class="mt-2 d-flex justify-content-between">
      <div class="actions d-flex">
        <a href="#" @click.prevent>
          <Reaction
            type="post"
            :reactions="reactions"
            :user-reaction="userReaction"
            @changed="(reaction) => reactionChanged(reaction.key, reply, reaction.isInstant)"
            @click.stop
          >
            <ion-button
              color="transparent"
              class="inline-button clickable"
              :class="!!userReaction && userReaction.reaction ? 'text-secondary' : 'text-light'"
            >
              <div class="d-flex align-items-center justify-content-center">
                <i
                  :color="!!userReaction && userReaction.reaction ? 'secondary' : 'primary'"
                  class="ti-thumb-up mr-1 icon"
                />
                <span
                  class="reaction-count"
                  :class="!!userReaction && userReaction.reaction ? 'text-secondary' : 'text-primary'"
                  >{{ reactions.total_count || 0 }}</span
                >
              </div>
            </ion-button>
          </Reaction>
        </a>
      </div>

      <span class="ml-auto mt-1 pb-1 reply">{{ replyCount || 0 }} repl{{ replyCount === 1 ? 'y' : 'ies' }}</span>
    </div>
  </ion-card>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { resizeUpload } from '@/shared/utils/upload';
import { truncateText } from '@/shared/utils/string';
import { Prop } from 'vue-property-decorator';
import { formatTimeAgo } from '@/shared/utils/dateTime';
import { stripHtmlTags } from '@/shared/utils/string';
import Reaction from '@/shared/components/Reaction/index.vue';
import store from '@/shared/store';

@Options({
  name: 'BlabRepliesCard',
  components: { Reaction },
})
export default class BlabRepliesCard extends Vue {
  @Prop({ default: () => ({}) }) reply!: any;
  @Prop({ default: () => ({}) }) count!: any;
  @Prop({ default: false }) showBlab!: boolean;

  public first = first;
  get = get;
  formatTimeAgo = formatTimeAgo;
  stripHtmlTags = stripHtmlTags;
  truncateText = truncateText;
  resizeUpload = resizeUpload;

  public get windowWidth() {
    const { width } = useWindowSize();
    return width.value;
  }
  public async reactionChanged(reaction: string, reply: any, isInstant = false) {
    const reactionResp = await store.dispatch('BlabsModule/reactBlabReply', { reaction, blab: reply, isInstant });
    this.$emit('afterReaction', reactionResp);
  }

  public get reactions() {
    const { reaction_counts } = this.reply || {};
    return reaction_counts;
  }

  public get replyCount() {
    return get(this.count, `${this.reply.id}.child_count`);
  }

  public get userReaction() {
    if (this.reply.user_reaction) {
      return this.reply.user_reaction;
    }
  }

  public get isMobSmallScreen() {
    return this.windowWidth <= 500;
  }
}
</script>

<style lang="sass" scoped>
.title
  font-size: 18px
  font-weight: bold
.description
  font-size: 14px

.reply
  font-size: 14px

.icon
  font-size: 16px
  font-weight: bold

.actions
  max-width: 200px
.reaction-count
  font-size: 14px
  font-weight: bold

.blab-card
  overflow: unset !important

  @media(min-width: 420px)
    min-width: 100% !important
    max-width: 28% !important
  @media(max-width: 419px)
    min-width: 100% !important
    max-width: 50% !important

  .img
    min-width: 35px
    width: 35px !important
    height: 35px !important
    border-radius: 30px
    border: solid gray 0.1px !important
    object-fit: cover

.blabs-list
  overflow-x: auto
  overflow: unset !important
.blabs-list::-webkit-scrollbar
  display: none
.blabs-list
  -ms-overflow-style: none
  scrollbar-width: none
</style>
