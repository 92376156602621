<template>
  <div>
    <div class="container">
      <div class="h-100 row visuals">
        <template v-for="(visual, index) in visuals" :key="index">
          <div class="col-sm-6 col-md-4 mb-3">
            <div class="d-flex justify-content-center">
              <div
                class="visual position-relative clickable-item"
                :style="{ backgroundImage: `url(${visual.image})` }"
                @click="click(index)"
              >
                <div class="actions d-flex">
                  <Reaction
                    type="visual"
                    :reactions="visual.reaction_counts"
                    :user-reaction="visual.user_reaction"
                    @changed="(reaction) => reactionChanged(reaction.key, visual, reaction.isInstant)"
                    @click.stop
                  >
                    <ion-button
                      :color="!!visual.user_reaction && visual.user_reaction.reaction ? 'secondary' : 'primary'"
                      class="text-white p-1"
                      style="width: 48px; height: 37px"
                    >
                      <div class="d-flex align-items-center justify-content-center">
                        <i class="ti-thumb-up text-white" />
                        <span class="mx-1 reaction-count text-white">{{
                          visual.reaction_counts.total_count || 0
                        }}</span>
                      </div>
                    </ion-button>
                  </Reaction>
                </div>
              </div>
            </div>
            <div class="text-center visual-text mt-2">
              From
              <router-link
                v-if="get(visual, 'entity.type') == 'character'"
                :to="{ name: 'character-profile-new', params: { slug: get(visual.entity, 'slug') } }"
                class="visual-text"
                ><strong>{{ get(visual, 'entity.info.name', 'Unknown') }}</strong></router-link
              >
              <router-link
                v-else-if="get(visual, 'entity.type') == 'world'"
                :to="{ name: 'world-details', params: { slug: get(visual.entity, 'slug') } }"
                class="visual-text"
                ><strong>{{ get(visual, 'entity.name', 'Unknown') }}</strong></router-link
              >
              <strong v-else>Unknown</strong>

              by
              <router-link
                :to="{ name: 'profile', params: { username: get(visual, 'username', '') } }"
                class="visual-text"
                ><strong>{{ get(visual, 'username', 'Unknown') }}</strong></router-link
              >
            </div>
          </div>
        </template>
      </div>
    </div>

    <VisualPreviewModal
      :is-open="isVisualPreviewModalOpen"
      :image-id="selectedVisualImgId"
      :image-url="selectedVisualImg"
      :caption="selectedVisualCaption"
      :source="selectedVisualSource"
      :source-user="selectedVisualSourceUser"
      :image="currentImageData"
      :all-images="visuals"
      :active-index="currentIndex"
      @onReaction="(reactionResp) => onVisualReaction(reactionResp)"
      @dismissModal="closeVisualPreviewModal"
      @updated="click"
      @loadmore="loadMore"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Action } from 's-vuex-class';
import { Prop } from 'vue-property-decorator';
import { Paging, Image } from '@/shared/types/static-types';
import VisualPreviewModal from '@/shared/modals/VisualPreviewModal.vue';
import namespace from '@/shared/store/namespace';
import Reaction from '@/shared/components/Reaction/index.vue';

@Options({
  name: 'VisualsCard',
  components: { VisualPreviewModal, Reaction },
})
export default class VisualsCard extends Vue {
  @Prop({ default: () => {} }) visuals!: any;
  get = get;

  public paging: Paging | null = null;
  public selectedVisualImg = '';
  public selectedVisualImgId = '';
  public selectedVisualCaption = '';
  public selectedVisualSource = '';
  public selectedVisualSourceUser = '';
  public isVisualPreviewModalOpen = false;
  public currentImageData: any = {};
  currentIndex = 0;

  @Action('reactImage', { namespace: namespace.ImagesModule })
  public reactImage!: any;

  public openVisualPreviewModal() {
    this.isVisualPreviewModalOpen = true;
  }

  public click(index: number) {
    this.selectedVisualImg = this.visuals[index].image!;
    this.selectedVisualImgId = this.visuals[index].id || '';
    this.selectedVisualCaption = this.visuals[index].description || '';
    this.selectedVisualSource = this.visuals[index].source || '';
    this.selectedVisualSourceUser = this.visuals[index].source_user || null;
    this.currentImageData = this.visuals[index];
    this.currentIndex = index;

    this.openVisualPreviewModal();
  }

  public async reactionChanged(reaction: string, image: any, isInstant = false) {
    const resp = await this.reactImage({ reaction, image, isInstant });
    this.$emit('onReaction', { reactResp: resp, image });
  }

  public onVisualReaction(event: { reactResp: {}; image: Image }) {
    const imgindex = this.visuals.findIndex((char: any) => char.id === event.image.id);
    this.visuals[imgindex] = {
      ...event.image,
      ...get(event.reactResp, 'updatedReactionsData', {}),
    };
    if (this.currentImageData.id === this.visuals[imgindex].id) {
      this.currentImageData = this.visuals[imgindex];
    }
  }

  public closeVisualPreviewModal() {
    this.isVisualPreviewModalOpen = false;
    this.selectedVisualImg = '';
    this.selectedVisualImgId = '';
    this.selectedVisualCaption = '';
    this.selectedVisualSource = '';
    this.selectedVisualSourceUser = '';
  }

  public loadMore() {
    this.$emit('load');
  }
}
</script>

<style lang="sass">
.container
  @media(min-width: 570px)
    width: 699px
.visual-text
  font-size: 14px
  color: #214163
.dark .visual-text
  color: white
.reaction-count
  font-weight: bold

.visual
  width: 100%
  height: 300px !important
  background-size: 99.9%
  border-radius: 10px
  border: solid gray 0.1px
  background-repeat: no-repeat
  background-position: center
  background-color: #ccc
  @media(max-width: 575px)
    height: 400px !important
    width: 85%
</style>
