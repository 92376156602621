<template>
  <div>
    <div v-if="socialSpaces">
      <Grid :lg="2" :md="2" :sm="1" :scrollSm="false">
        <SocialSpaceCard
          class="grid-item"
          v-for="(space, index) of socialSpaces"
          :social-space="space"
          :key="index"
          :member-count="get(memberCounts, `${get(space, 'id')}.member_count`) || 0"
          :room-count="get(roomCounts, `${get(space, 'id')}.room_count`) || 0"
          :index="index"
          :openBlank="true"
        />
      </Grid>
      <ion-button v-show="nextPageExists" class="clickable" @click="requestLoadMore">Load More</ion-button>
    </div>
    <div v-else class="no-data">No Social Space yet</div>
  </div>
</template>

<script lang="ts" setup>
import Grid from '@/shared/components/storage/Grid.vue';
import SocialSpaceCard from '@/shared/components/storage/SocialSpaceCard.vue';
import { getSocialSpaceRoomsCount, getWorldsMemberCounts } from '@/shared/actions/worlds';

const props = defineProps({ socialSpaces: { type: Array }, paging: { type: Object } });
const emits = defineEmits(['load']);
const paging = toRef(props, 'paging');
const socialSpaces = toRef(props, 'socialSpaces');
const chatUnreadCounts = ref({});
const memberCounts = ref({});
const roomCounts = ref({});
const nextPageExists = computed(() => {
  return !!paging.value?.next;
});

const requestLoadMore = () => {
  emits('load');
};

const fetchSocialSpacesMemberCounts = async () => {
  memberCounts.value = {};
  const resp = await getWorldsMemberCounts(map(socialSpaces.value, 'id') as string[]);
  memberCounts.value = keyBy(resp, 'world_id');
};
const fetchSocialSpaceRoomCounts = async () => {
  roomCounts.value = {};
  const resp = await getSocialSpaceRoomsCount(map(socialSpaces.value, 'id') as string[]);
  roomCounts.value = keyBy(resp, 'world_id');
};

watch(
  socialSpaces,
  () => {
    if (!isEmpty(socialSpaces.value)) {
      fetchSocialSpacesMemberCounts();
      fetchSocialSpaceRoomCounts();
    }
  },
  { immediate: true, deep: true }
);
</script>

<style scoped lang="sass"></style>
