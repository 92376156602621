<template>
  <ion-segment
    scrollable
    mode="md"
    color="primary"
    class="tabs compact no-shadow bg-transparent"
    :value="activeIndex"
    @ion-change="segmentChanged($event)"
  >
    <ion-segment-button v-for="(tab, index) of tabs" :key="index" class="shrink bg-transparent" :value="index">
      <ion-label>{{ tab.name }} </ion-label>
    </ion-segment-button>
  </ion-segment>
</template>

<script lang="ts" setup>
import { Tab } from '@/shared/types/static-types';

const props = defineProps({
  tabs: {
    type: Array as PropType<Tab[]>,
    required: true,
  },
  activeIndex: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['update:activeIndex', 'tab-changed']);
const segmentChanged = ($event: CustomEvent) => {
  const tabIndex = $event.detail.value as number;
  const tab = props.tabs[tabIndex];

  emit('update:activeIndex', tabIndex);
  emit('tab-changed', tab);
};
</script>

<style scoped lang="sass">
.disabled
  opacity: 0.5
  pointer-events: none
.shrink
  flex: none !important
.tabs
  height: auto
  min-height: 30px !important
  ::v-deep
    ion-segment-button
      &::before
        display: none !important
      height: 28px !important
      min-height: unset !important
      min-width: unset !important
      border-radius: 20px
      border: 1.5px solid #7A7E9B
      color: #7A7E9B
      margin: 0 5px
      &:first-of-type
        margin-left: 0 !important
      &:last-of-type
        margin-right: 0 !important
      &::part(indicator)
        opacity: 0 !important
      &.segment-button-checked
        background: #214163 !important
        color: #FFF
        border: 0 !important
        *
          background: transparent !important
          color: #FFF !important
</style>
